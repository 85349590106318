import React from "react"
import parse from 'html-react-parser';

import Layout from '../components/Layout'
import { Row, Col, Accordion, Card, Button } from "react-bootstrap"
import { Helmet } from "react-helmet"

import { Link } from 'gatsby'




const innerPage = props => {
  const { pageContext } = props
  const { metaContent, pageContent } = pageContext

  console.log('Page Content', pageContent)

  return (   

            <Layout page="careers">
                <Helmet>
                    <body className="careers-body" />
                    <meta charSet="utf-8" />
                    <meta name="description" content={metaContent.description} /> 
                    <title>{metaContent.title}</title>
                </Helmet>

                <div className="consistent-gap">

                    <div className="text-center">

                            <h1 className="heading-careers mx-4">{pageContent.title}</h1>
                            { parse(pageContent.description) }
                            <a href="#faq" className="btn btn-outline-primary btn-lg mt-4">{pageContent.button}<svg className="down-arrow" width="32" height="32" viewBox="0 0 32 32"><g fillRule="evenodd"><path transform="rotate(-45 26.616 9.647)" d="M5.364 -0.236H7.364V15.764H5.364z"></path><path transform="scale(-1 1) rotate(-45 -1.419 53.188)" d="M5.364 9.764H7.364V25.764H5.364z"></path><path d="M2 14H26V16H2z" transform="rotate(-90 15 14)"></path></g></svg></a>

                    </div>

                </div>

                <div className="container-fluid purple-bg">

            <div className="container">

              <div className="consistent-gap">

                <div className="content careers-content text-center">

                    <h2 className="white">{pageContent.middle}</h2>

                    <Row className="mfeature-panel">
                      {pageContent.pointers.map((data, index)=> (
                            <Col md={6}>
                              <div className="mfeature-box">
                                <div className="mfeature-icon-big">
                                  {parse(data.point.icon)}
                                </div>
                                <div className="mfeature-text">
                                  <div className="mfeature-title">{data.point.heading}</div>
                                  <div className="mfeature-details">{data.point.description}</div>
                                </div>                              
                              </div>                      
                            </Col>
                          )
                      )}
                    </Row>
                    
                </div>

              </div>
              

            </div>

          </div>


          <div className="container">

            <div className="variable-gap">    

                <div id="sauce" className="about-panel">
                      <h2 className="heading-small">{pageContent.tipsTitle}</h2>
                      <p className="description-small">{pageContent.tipsDescription}</p>            
                </div>

                <div className="sauce-panel">

                    {pageContent.tips.map((data, index)=> (
                            <div className="sauce-box">
                            <div className="sauce-icon tick-icon"></div>
                                <div className="sauce-text">
                                    {data.point}
                                    {index===pageContent.tips.length - 1 ? <><br /> <br/> <Link to="/contact" className="underline purple">Still Confused? Get Free Consultation</Link></> : null}
                                </div>
                            </div> 
                          )
                   )}

                </div>


                    <div id="faq" className="about-panel">
                        <h2 className="heading-small">FAQ</h2>
                        <p className="description-small">Common queries answered</p>            
                    </div>

                    <Accordion className="accordion" defaultActiveKey="0">
                        {pageContent.faq.map((data, index)=> (
                            <Card>
                                <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey={index.toString()}>
                                    {data.point.heading}
                                </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey={index.toString()}>
                                <Card.Body>{data.point.description}</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        )
                        )}
                    </Accordion>

                <div class="chat-panel text-center">
                  <div>
                      <h2 class="heading-small">{pageContent.callToAction}</h2>
                          <Link to="/contact" className="btn btn-outline-primary btn-lg mt-4">Free Consultation</Link>
                  </div>
                </div>

            </div>
              
          </div>
        
        </Layout>

  )
}
export default innerPage